<template lang="pug">
.personal-information__confirm(:class='{ "checked": isChecked }')
  input(
    type='checkbox'
    v-model='isChecked'
    :disabled="disabled"
    :class='{ "awesome-custom-checkbox": true, "checked": isChecked }')
  label.awesome-checkbox-label
    span.awesome-checkbox-text(:class='{ "checked": isChecked }')
      slot
    .awesome-checkbox-inner
      .awesome-checkbox-square
</template>

<script>
export default {
  name: "FCheckbox",

  props: {
    value: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },

  emits: ['input'],

  computed: {
    isChecked: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.personal-information {

  &__confirm {
    position: relative;
    height: 36px;

    &.checked {
      background: $blue100;
      border-radius: 10px;
    }

    .awesome-checkbox-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      width: 100%;
      height: 100%;
      padding: 9px 16px;
    }

    .awesome-custom-checkbox {
      cursor: pointer;
      position: absolute;
      opacity: 0;
      z-index: 1;

      &:hover + .awesome-checkbox-label .awesome-checkbox-text {
        color: $blue600;
      }

      &:hover + .awesome-checkbox-label .awesome-checkbox-inner {
        border: 1.5px solid $blue600;
      }

      &:disabled, &:disabled:hover {
        cursor: not-allowed;

        & + .awesome-checkbox-label .awesome-checkbox-text {
          color: $gray200;
        }

        & + .awesome-checkbox-label .awesome-checkbox-inner {
          border: 1.5px solid $gray200;
        }
      }
    }

    .awesome-checkbox-inner {
      cursor: pointer;
      margin-left: 10px;
      position: relative;
      height: 16px;
      width: 16px;
      border: 1.5px solid $gray600;
      border-radius: 4px;
    }

    .awesome-checkbox-square {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 10px;
      width: 10px;
      background: $blue600;
      border-radius: 2px;
      display: none;
    }

    .awesome-custom-checkbox:checked ~ label .awesome-checkbox-inner {
      border: 1.5px solid $blue600;
    }

    .awesome-custom-checkbox:checked ~ label .awesome-checkbox-inner .awesome-checkbox-square {
      display: block;
    }

    .awesome-checkbox-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: $gray600;

      &.checked {
        color: $blue600;
      }
    }
  }
}
</style>
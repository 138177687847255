<template lang="pug">
.row.market-header
  .col-12
    .market-block-settings
      .market-block-settings__row
        .market-block-settings__select.deals-params-main__container
          select-box(
            tabIndex="1",
            :items="getFiats",
            name="FiatSelect",
            :defaultIcon="defaultFiatIcon",
            v-model.number="filters.currency_id",
            :is-disabled="isLoading",
          )

          select-box(
            tabIndex="2",
            :items="payments",
            name="PaymentSelect",
            :defaultIcon="defaultPaymentIcon",
            v-model.number="selectedPayment",
            styleWidth="33",
            :is-disabled="isLoading",
          )

          select-box(
            tabIndex="3",
            :items="isSaleOptions",
            name="IsSaleSelect",
            :show-icon="false",
            v-model.number="filters.is_sale",
            :show-label="true",
            styleWidth="18",
            :is-disabled="isLoading",
          )

        .desktop-extra-options
          a.accordion-toggle(:class="isOpenOptions ? 'active' : ''", @click="toggleOpenOptions") Дополнительные параметры

      .market-block-settings__row.mt-4(v-if="isOpenOptions")
        .deal-params-additional__container
          .deals-params-additional__row
            .deals-params-amount__container
              .deals-params-amount__title Сумма сделки в валюте
              .deals-params-amount__input_container.edit-offer-block__row-50
                input-number(
                  title="От",
                  name='"Валюта от"',
                  v-model.number="filters.fiat_amount_from",
                  :label="currencyCode",
                  :step="fiatInputStep",
                  :disabled="isFiatAmountDisabled || isLoading",
                  v-validate="'decimal:2|min_value:0'"
                )
                input-number(
                  title="До",
                  name='"Валюта до"',
                  v-model.number="filters.fiat_amount_to",
                  :label="currencyCode",
                  :step="fiatInputStep",
                  :disabled="isFiatAmountDisabled || isLoading",
                  v-validate="'decimal:2|min_value:0'"
                )
              el-popover(
                v-if="isFiatAmountDisabled",
                placement="top-start"
                title="Ошибка"
                trigger="click"
                content="Для активации данных параметров необходимо выбрать валюту."
              )
                .deals-params-amount__disabled(slot="reference")
            .deals-params-amount__container.edit-offer-block__row-50
              .deals-params-amount__title Сумма сделки в криптовалюте
              .deals-params-amount__input_container
                input-number(
                  title="От",
                  name='"Криптовалюта от"',
                  v-model.number="filters.crypto_amount_from",
                  :label="cryptocurrencyCode",
                  :step="cryptoInputStep",
                  :disabled="isLoading",
                )
                input-number(
                  title="До",
                  name='"Криптовалюта до"',
                  v-model.number="filters.crypto_amount_to",
                  :label="cryptocurrencyCode",
                  :step="cryptoInputStep",
                  :disabled="isLoading",
                )
          .deals-params-additional__row
            .dials-params-textfield__container
              el-input.dials-params-textfield__input(
                placeholder="Реквизит сделки",
                v-model="filters.requisite_text",
                :disabled="isLoading",
              )
            .dials-params-textfield__container
              el-input.dials-params-textfield__input(
                placeholder="Логин партнера",
                v-model="filters.login",
                :disabled="isLoading",
              )
            .deals-period__container
              el-date-picker(
                v-model="dealsDate"
                type="daterange",
                range-separator="—",
                start-placeholder="Начало периода",
                end-placeholder="Конец периода",
                format="dd-MM-yyyy",
                value-format="dd/MM/yyyy",
                :disabled="isLoading",
                @change="updateDealsDateFilter",
              )
          .deals-params-additional__row
            .dials-params-textfield__container
              .deals-status__container(v-if="!isActiveTab")
                f-checkbox(v-model="filters.finished" :disabled="filters.canceled === true || isLoading") Только завершенные
                f-checkbox(v-model="filters.canceled" :disabled="filters.finished === true || isLoading") Только отмененные
              .deals-status__container
                f-checkbox(
                  v-model="filters.requisite_exists"
                ) Только с выданными реквизитом
      .market-block-settings__row.mt-4(v-if="isOpenOptions && errors.items.length")
        ul
          li.errors(v-for="error in errors.all()") {{ error }}
</template>

<script>
import InputNumber from '@/components/Market/Partials/InputNumber';
import InputRange100 from '@/components/Market/Partials/InputRange100';
import SelectBox from '@/components/Market/Partials/SelectBox';
import { mapGetters } from 'vuex';
import { REQUEST_CRYPTO, REQUEST_CURRENCIES, REQUEST_PAYMENTS } from '../../store/actions/ad';
import _ from 'lodash';
import { differenceObject } from '@/components/Market/Mixins/differenceObject';
import analytics from '@/components/mixins/analytics';
import { DEALS_FILTER_SET } from "../../store/actions/deal";
import cfg from '../../../config.js';
import { DASHBOARD_DEALS } from "@/store/actions/dashboard";
import FCheckbox from "@/components/UI Kit/checkbox/FCheckbox.vue";

export default {
  name: 'FiltersDeals',
  components: {
    FCheckbox,
    InputNumber,
    InputRange100,
    SelectBox,
  },

  mixins: [analytics],

  data() {
    return {
      defaultFiatIcon: cfg.default.defaultFiatIcon,
      defaultPaymentIcon: cfg.default.defaultPaymentIcon,
      fiatInputStep: 1,
      cryptoInputStep: 0.0001,
      debounceTime: 500,
      isOpenOptions: false,
      cryptos: [],
      fiats: [],
      paymentsAll: [],
      dealsDate: [],
      isFinished: true,
      isCanceled: true,
      isSaleOptions: [
        {
          label: 'Тип сделки',
          value: null,
        },
        {
          label: 'Покупка',
          value: 0,
        },
        {
          label: 'Продажа',
          value: 1,
        },
      ],
      fiatAmount: {
        from: null,
        to: null,
      },
      cryptoAmount: {
        from: null,
        to: null,
      },
      selectedPayment: 0,
      currentCurrency: null,
      filters: {
        is_sale: this.$store.state.deal.dealsFilter.is_sale || null,
        currency_id: this.$store.state.deal.dealsFilter.currency_id || null,
        fiat_amount: null,
        fiat_amount_from: null,
        fiat_amount_to: null,
        crypto_currency_id: 1,
        crypto_amount: null,
        crypto_amount_from: null,
        crypto_amount_to: null,
        requisite_text: null,
        requisite_exists: false,
        login: null,
        created_from: null,
        created_to: null,
        payment_system_id: this.$store.state.deal.dealsFilter.payment_system_id || null,
        finished: false,
        canceled: false,
      },
    };
  },

  computed: {
    ...mapGetters([
      'isAuthenticated',
      'dealsFilter',
      'loading'
    ]),

    isLoading() {
      return this.loading(DASHBOARD_DEALS) === 'loading';
    },

    // filtered and prepared payments array
    payments() {
      return [
        {
          value: 0,
          ads: 0,
          payment_system_id: 0,
          bank_id: 0,
          short_title: 'Платежные методы',
        },
        ...this.paymentsList,
      ];
    },

    currencyCode() {
      if (this.filters.currency_id && this.fiats) {
        const current = this.fiats.find(fiat => fiat.value === this.filters.currency_id);
        if (current) {
          return current.short_title;
        }
      }
      return cfg.default.defaultCurrencyCode;
    },

    cryptocurrencyCode() {
      if (this.filters.crypto_currency_id && this.cryptos) {
        const current = this.cryptos.find(crypto => crypto.value === this.filters.crypto_currency_id);
        if (current) {
          return current.short_title;
        }
      }
      return cfg.default.defaultCryptocurrencyCode;
    },

    paymentsList() {
      return this.paymentsAll.map(item => {
        return {
          value: item.value,
          ads: 0,
          payment_system_id: item.value,
          short_title: item.label,
          icon: item.icon,
        };
      });
    },

    getFiats(){
      return [
        {
          value: 0,
          label: 'Валюты',
          short_title: 'Валюты',
        },
        ...this.fiats,
      ];
    },

    formattedFilters() {
      const filters = {...this.filters};
      if (filters.currency_id === 0) {
        filters.currency_id = null;
      }

      if (filters.crypto_currency_id === 0) {
        filters.crypto_currency_id = null;
      }

      if (filters.payment_system_id === 0) {
        filters.payment_system_id = null;
      }

      filters.finished = filters.finished ? 1 : 0;

      filters.canceled = filters.canceled ? 1 : 0;

      filters.requisite_exists = filters.requisite_exists ? 1 : 0;

      if (
          (filters.fiat_amount_from || filters.fiat_amount_to)
          && filters.fiat_amount_from === filters.fiat_amount_to
      ) {
        filters.fiat_amount = filters.fiat_amount_from;
        filters.fiat_amount_from = null;
        filters.fiat_amount_to = null;
      }

      if (
          (filters.crypto_amount_from || filters.crypto_amount_to)
          && filters.crypto_amount_from === filters.crypto_amount_to
      ) {
        filters.crypto_amount = filters.crypto_amount_from;
        filters.crypto_amount_from = null;
        filters.crypto_amount_to = null;
      }
      return filters;
    },
    isFiatAmountDisabled() {
      return !this.filters.currency_id;
    },

    isActiveTab() {
      return !!this.$store.state.deal.dealsFilter.is_active;
    }
  },

  watch: {
    //
    filters: {
      handler: async function(newFilter) {
        if (newFilter.currency_id !== this.currentCurrency) {
          await this.loadPayments();
          this.currentCurrency = newFilter.currency_id;
        }
        this.debouncedEmitFilters();
      },
      deep: true,
    },

    //
    isAuthenticated: {
      handler: function() {
        this.debouncedEmitFilters();
      },
    },

    //
    selectedPayment: function(val) {
      const payment = this.payments.find(item => {
        return item.value === val;
      });

      if (payment) {
        this.filters.payment_system_id = payment.payment_system_id;
      } else {
        this.resetPayment();
      }
    },
  },

  created() {
    this.debouncedEmitFilters = _.debounce(this.emitFilters, this.debounceTime);
  },

  async mounted() {
    await this.loadCryptos();
    await this.loadFiats();
    await this.loadPayments();
    this.currentCurrency = this.filters.currency_id;
    this.current = this.filters.currency_id;
  },

  methods: {

    updateDealsDateFilter() {
      this.filters.created_from = this.dealsDate ? this.dealsDate[0] : null;
      this.filters.created_to = this.dealsDate ? this.dealsDate[1] : null;
    },

    // reset payment filters when 'Все' selected
    resetPayment() {
      this.filters.payment_system_id = 0;
      this.filters.fiat_amount = null;
      this.filters.fiat_amount_from = null;
      this.filters.fiat_amount_to = null;
    },

    //
    resetCurrency() {
      this.filters.currency_id = 0;
    },

    // select payment and band or use default
    trySelectPayment() {
      const payment = this.payments.find(
          item => item.payment_system_id === this.filters.payment_system_id
      );
      if (payment) {
        this.selectedPayment = payment.id;
      } else {
        this.selectedPayment = 0;
      }
    },
    trySelectCurrency() {
      const currencies = this.getFiats.find(item => {
        return item.value === this.filters.currency_id;
      });
      if(!currencies){
        this.resetCurrency();
      }
    },

    //
    emitFilters() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.$store.dispatch(DEALS_FILTER_SET, differenceObject(this.formattedFilters, this.dealsFilter));
        }
      });
    },

    //
    toggleOpenOptions() {
      this.isOpenOptions = !this.isOpenOptions;
      if (this.isOpenOptions) {
        this.sendAnalytics('Filter', 'filter_open');
      } else {
        this.sendAnalytics('Filter', 'filter_hide');
      }
    },

    //
    async loadPayments() {
      this.resetPayment();
      this.loadAllPayments();
      this.trySelectCurrency();
      this.trySelectPayment();
    },

    //
    loadCryptos() {
      this.$store.dispatch(REQUEST_CRYPTO).then(data => {
        this.cryptos = data;
      });
    },

    //
    async loadFiats() {
      await this.$store.dispatch(REQUEST_CURRENCIES,).then(data => {
        this.fiats = data;
      });
    },

    //
    async loadAllPayments() {
      let payments = await this.$store.dispatch(REQUEST_PAYMENTS, {
        currency_id: this.filters.currency_id
      });
      this.paymentsAll = payments || [];
    },

  },

};
</script>

<style>
.market-block-settings__select.deals-params-main__container {
  justify-content: flex-start;
}

.desktop-extra-options .accordion-toggle {
  cursor: pointer;
}

.market-block-settings__row li.errors {
  color: red;
}

.deals-params-additional__row {
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.deals-period__container {
  flex: 0 0 calc(50% - 20px);
  margin-right: 20px;
}

.deals-status__container {
    display: flex;
    flex-direction: column;
    gap: 4px;
  margin-right: 20px;
  padding-top: 8px;
}

.deals-params-amount__input_container {
  display: flex;
}

.deals-params-amount__container {
  position: relative;
  flex: 1;
}

.deals-params-amount__disabled {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: not-allowed;
}

.deals-params-amount__title {
  text-align: center;
  margin-bottom: 10px;
  font-weight: 500;
}

.dials-params-textfield__container {
  display: flex;
  text-decoration: row;
  margin-right: 20px;
  flex: 1;
}

.dials-params-textfield__input input {
  background-color: $gray50;
  border: 1px solid tansparent;
  font-size: 16px;
  color: $gray600;
  border-radius: 10px;
  height: 50px;
  padding-left: 20px;
  padding-right: 10px;
}

.dials-params-textfield__input input::placeholder {
  color: #768293;
}

.deal-params-additional__container {
  width: 100%;
  padding-left: 20px;
}
</style>

<template lang="pug">
  .operation-block
    .operation-block__arrow
    .operation-block__data
      span ID: {{ deal.id }}
    .operation-block__data
      span {{ divideDate(deal.created_at).dateDay }}
      time {{ divideDate(deal.created_at).time }}
    //.operation-block__logo
    //  img(:src='getDealPaymentIcon', :alt='getDealPayment')
    .operation-block__purchase(v-if="showRequisites")
      p Реквизиты
      .requisites-block
        span.requisites-value {{ getDealRequisites }}
        i.el-icon-document-copy.icon-copy(title="Копировать" @click="copyToClipboard(getDealRequisites)")
    .operation-block__purchase(v-else)
      p(:class="{'type-sale': deal.is_sale, 'type-buy': !deal.is_sale,}") {{ getDealDescription }}
      span {{getDealPayment}}
    .operation-block__status(:class="getOperationClass(deal)")
      span(:title="getStatusHead(deal.status.title)")
      p  {{ getStatus(deal.status.title) }}
    .operation-block__pay(v-if="showRequisites")
      button.btn.btn-success.btn-finex(@click="acceptDialog('paid', 'Подтвердить оплату')",) Я оплатил
    .operation-block__given
      p {{ is_active === 0  ? 'Отдано' : 'Отдаю'}}
      span {{ getSellCount }}
    .operation-block__received
      p  {{ is_active === 0 ? 'Получено' :  'Получаю'}}
      span {{ getBuyCount }}
    .operation-block__to-deal
      router-link(
      :to="{ name: 'Deal', params: { id: deal.id } }",
      )
        button.btn.btn__main(@click="sendAnalytics('Operations', 'deal_open', false)") К сделке
      .operation-block__changes(v-if="changesCount") {{ changesCount }}
</template>
<script>
  import { mapGetters } from 'vuex';
  import utils from '@/components/mixins/utils';
  import analytics from '@/components/mixins/analytics';
  import { DEAL_ACTION } from '@/store/actions/deal';

  export default {
    mixins: [
      utils,
      analytics,
    ],
    props: {
      deal: {
        type: Object,
        default() {
          return {};
        },
      },
      is_active: {
        type: Number,
        default: 1,
      },
    },
    computed: {
      ...mapGetters([
        'loading',
        'get_status',
        'getProfile',
        'dealChangesCount'
      ]),
      isOffer() {
        return this.getProfile.id === this.deal.ad.author.id;
      },
      getDealPayment() {
        if (this.deal.bank) {
          return `${this.deal.ad.payment_system.title} "${this.deal.bank.label}"`;
        }
        return this.deal.ad.payment_system.title;
      },
      getDealPaymentIcon() {
        if (this.deal.bank) {
          return this.deal.bank.icon;
        }

        return this.deal.payment_system.icon;
      },
      showRequisites() {
        return this.deal.status.title === 'Seller requisite' && (this.isOffer && !this.deal.ad.is_sale || !this.isOffer && this.deal.ad.is_sale);
      },
      getSellCount() {
        return this.isOffer ?
          this.deal.ad.is_sale ?
            `${this.getCryptoAmount(this.isOffer, this.deal)} ${this.deal.ad.crypto_currency.code.toUpperCase()}` :
            `${this.deal.fiat_amount} ${this.deal.ad.currency.code.toUpperCase()}` :
          this.deal.ad.is_sale ?
            `${this.deal.fiat_amount} ${this.deal.ad.currency.code.toUpperCase()}` :
            `${this.getCryptoAmount(this.isOffer, this.deal)} ${this.deal.ad.crypto_currency.code.toUpperCase()}`;
      },
      getBuyCount() {
        return this.isOffer ?
          this.deal.ad.is_sale ?
            `${this.deal.fiat_amount} ${this.deal.ad.currency.code.toUpperCase()}` :
            `${this.getCryptoAmount(this.isOffer, this.deal)} ${this.deal.ad.crypto_currency.code.toUpperCase()}` :
          this.deal.ad.is_sale ?
            `${this.getCryptoAmount(this.isOffer, this.deal)} ${this.deal.ad.crypto_currency.code.toUpperCase()}`:
            `${this.deal.fiat_amount} ${this.deal.ad.currency.code.toUpperCase()}`;

      },
      getDealDescription() {
        return this.isOffer ?
          this.deal.ad.is_sale ?
            'Продажа через' :
            'Покупка через' :
          this.deal.ad.is_sale ?
            'Покупка через' :
            'Продажа через';
      },
      getDealRequisites() {
        return this.deal.requisites.seller_info;
      },
      changesCount() {
        return this.dealChangesCount(this.deal.id) > 99 ? '99+' : this.dealChangesCount(this.deal.id);
      },
    },
    methods: {
      getStatus(name) {
        if (name === 'On time check') {
          const dealStatus = this.deal.status_history.find((status) => status.title === 'Canceled' || status.title === 'Finished');
          const status = this.$store.state.statuses.find(status => status.name === dealStatus.title);
          return status.title;
        } else {
          const status = this.$store.state.statuses.find(status => status.name === name);
          return status.title;
        }
      },
      getStatusHead(name) {
        const status = this.$store.state.statuses.find(status => status.name === name);
        return status.title;
      },
      getOperationClass(deal) {
        return deal.status.title.toLowerCase();
      },
      dealAction(action) {
        this.sendAnalytics('Deal', `deal_${action}`, false);
        if (action === 'paid') {
          this.sendAnalytics('Deal', `send_submit`, false, ['google', 'yandex']);
        } else if (action === 'finish') {
          this.sendAnalytics('Deal', 'create_submit', false, ['google', 'yandex']);
          this.sendAnalytics('Deal', 'Purchase', false, ['facebook']);
        }
        this.$store.dispatch(DEAL_ACTION, {dealId: this.deal.id, action});
      },
      acceptDialog(action, typeText) {
        this.$confirm('Помните, это действие необратимо!', `${typeText}?`, {
          confirmButtonText: 'Да',
          cancelButtonText: 'Нет',
          type: 'warning'
        }).then(() => {
          this.dealAction(action);
        })
      },
      copyToClipboard(value) {
        navigator.clipboard.writeText(value).then(() => {
          this.$message({
            message: 'Реквизиты успешно скопированы',
            type: 'success'
          });
        }).catch(err => {
          console.log('Ошибка при копировании адреса', err);
        });
      },
    },
  }
</script>
<style>
</style>

<template lang="pug">
  .block-range
    .block-range__text
      p {{title}}
      span {{value}}%
    input.custom-range(
      type='range',
      :value="value",
      @input="$emit('input', $event.target.value)"
      min="0",
      max="100",
      step="1"
    )
</template>

<script>
export default {
  name: 'InputRange100',
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: 0
    },
  }
};
</script>

<style scoped>
  .custom-range {
    cursor: pointer;
  }
</style>
<template lang="pug">
  .d-e-blocks.width-block.take-set-input
    span {{title}}
    .block-wrap
      input.i-number(
        type='number',
        :value="value",
        :step="step",
        :disabled="disabled",
        lang="en-150",
        min="0",
        @input="$emit('input', parseFloat($event.target.value))"
      )
      .currency-name
        | {{label}}
</template>

<script>
export default {
  name: 'InputNumber',
  props: {
    title: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: 0
    },
    step: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  }
};
</script>

<style scoped>
  .take-set-input {
    margin-right: 20px;
  }

  .i-number {
      border-radius: 10px 0 0 10px;
  }
</style>

<template lang="pug">
  .main-content.layout-main__centering.container.pb-5(
    v-loading='isLoading',
    element-loading-text='Загрузка данных...',
  )
    DealsContainer(:showFilters="true")
</template>
<script>
import {mapGetters} from 'vuex';
import {
  AD_ALL,
} from '@/store/actions/ad';
import DealsContainer from "../components/Deals/DealsContainer";


export default {
  components: {
    DealsContainer,
  },
  computed: {
    ...mapGetters([
      'loading',
    ]),
    isLoading() {
      return this.loading(AD_ALL) === 'loading';
    },
  },
  mounted() {
    this.$store.dispatch(AD_ALL);
  },
}
</script>

<style scoped>
.main-content {
  padding-top: 1rem;
}
</style>

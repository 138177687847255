<template lang="pug">
  section.content-tab
    .exchange-deals-filters__container
      FiltersDeals(v-if="showFilters")
    section.page-info.page-info_purse
      .page-info__container
        .page-deals-no-deals(v-if="dealsDashboard.length === 0") Нет {{ this.is_active ? 'активных' : 'завершенных' }} сделок
        Deal(v-else v-for="deal in dealsDashboard", :deal="deal", :is_active="is_active" :key="`deal-${deal.id}`")
    .deals-pagination
      el-pagination(
        v-if='hasPagination',
        :total='getDashboardPagination.total',
        :page-size='parseInt(getDashboardPagination.per_page)',
        :current-page='getDashboardPagination.current_page',
        :disabled='isLoading'
        background,
        layout='prev, pager, next',
        @current-change='changeCurrent',
      )
</template>
<script>
import { mapGetters } from 'vuex';
import { DASHBOARD_DEALS } from '../../store/actions/dashboard';
import Deal from '../../components/Wallet/Deal';
import {DEALS_FILTER_SET} from "../../store/actions/deal";
import FiltersDeals from "@/components/Deals/FiltersDeals";

export default {
  components: {
    FiltersDeals,
    Deal
  },
  props: {
    is_active: {
      type: Number,
      default: 1
    },
    showFilters: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      pagination: {},
      is_loading: false
    };
  },
  computed: {
    ...mapGetters([
      'get_status',
      'getProfile',
      'getDashboardPagination',
      'dealsDashboard',
      'dealsFilter',
      'loading',
    ]),
    isLoading() {
      return this.loading(DASHBOARD_DEALS) === 'loading';
    },
    hasPagination() {
      return !!this.getDashboardPagination && this.getDashboardPagination.total_pages > 1;
    }
  },
  watch: {
    is_active() {
      this.getData();
    },
    dealsFilter: {
      handler: function() {
        if(!this.is_loading) {
          this.getData();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    changeCurrent(page) {
      this.getData(page);
    },
    getData(page = 1) {
      this.is_loading = true;
      let data = {
        params: {
          ...this.dealsFilter,
          page,
          is_active: this.is_active,
        },
        type: this.type
      };
      this.$store.commit(DEALS_FILTER_SET, { key: 'is_active', value: this.is_active });
      this.$store.dispatch(DASHBOARD_DEALS, data).then(response => {
        this.pagination = response.pagination;
        this.is_loading = false;
      });
    },
  }
};
</script>
<style>
  .deals-pagination {
    text-align: center;
    margin: 10px;
  }

  .page-deals-no-deals {
    margin-top: 20px;
  }

  .exchange-deals-filters__container {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 3px solid #F7F8FA;
  }
</style>

<template lang="pug">
  .select-box.select-box-desktop(:style="styleWidth ? `width: ${styleWidth}%` : ''")
    .select-box__current(
      :class="{ 'select-box__current-opened' : isSelectOpen, 'select-box__current-disabled' : isDisabled }",
      :tabindex='tabIndex',
      @blur="isSelectOpen = false",
      ref="select",
      @click="toggleSelect",
    )
      .select-box__value(v-for="item in items", :key="item.value")
        input.select-box__input(
          type='radio',
          :name="name",
          :checked="item.value === value ? 'checked' : ''",
          :id="itemHtmlId(item)",
          :value="item.value",
          @input="changeInput($event.target.value)"
        )
        p.select-box__input-text.select-box__input-text_(@click="checkSelectOpen()") {{ getTitle(item) }}
          //span.select-box__block-icon.select-box__block-icon(
          //  v-if="showIcon"
          //  :style="itemIcon(item)",
          //)
      img.select-box__icon(src='/img/arrow-down-gray.svg', alt='Arrow Icon', aria-hidden='true' @click="checkSelectOpen()")
    ul.select-box__list
      li(v-for="(item, index) in items", :key="`${index}-${item.value}`")
        label.select-box__option(:for='itemHtmlId(item)') {{ getTitle(item) }}
          //span.select-box__block-icon.select-box__block-icon(v-if="showIcon" :style="itemIcon(item)")
</template>

<script>
export default {
  name: 'SelectBox',

  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    name: {
      type: String,
      default: '',
    },
    defaultIcon: {
      type: String,
      default: '',
    },
    tabIndex: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: 0,
    },
    styleWidth: {
      type: String,
      default: '',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isSelectOpen: false,
    }
  },
  methods: {
    //
    itemHtmlId(item) {
      return this.name + '-' + item.value;
    },

    //
    itemIcon(item) {
      const icon = item.icon ? item.icon : this.defaultIcon;

      return `background: url("${icon}") no-repeat center / 100% auto;`;
    },
    checkSelectOpen() {
      this.isSelectOpen = !this.isSelectOpen;
      if (!this.isSelectOpen) {
        this.$refs.select.blur();
      }
    },
    getTitle(item) {
      return this.showLabel ? item.label : item.short_title;
    },
    changeInput(value) {
      if (value === '') {
        value = null;
      }
      this.$emit('input', value);
    },
    toggleSelect() {
      if (this.isDisabled) {
        return;
      }
      this.isSelectOpen = !!this.isSelectOpen;
    }
  },
};
</script>

<style scoped>
.select-box-desktop {
  margin-right: 20px;
}
</style>

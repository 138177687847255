<template lang="pug">
  .tabs-content.tabs-purse.wrapper_gray-border
    .tabs
      input#tab1.tab1(
        :disabled="isLoading && currentTab === 'finishedOperations'"
        type='radio',
        name='tabs',
        checked=''
      )
      label(
        v-loading="isLoading && currentTab === 'activeOperations'",
        :class="{'tab-disabled': isLoading}"
        for='tab1',
        title='Просмотр текущих операций',
        @click="changeTab('activeOperations')",
      ) Текущие сделки {{ activeDeals }}

      input#tab2.tab2(
        :disabled="isLoading && currentTab === 'activeOperations'"
        type='radio', name='tabs'
      )
      label(
        v-loading="isLoading && currentTab === 'finishedOperations'",
        :class="{'tab-disabled': isLoading}"
        for='tab2',
        title='Просмотр завершенных операций',
        @click="changeTab('finishedOperations')"
      ) Завершенные сделки
      label(title='Поиск сделки по ID', @click="open" for="tab3") &#128269;
      DealsList(:showFilters="showFilters" :is_active="1", v-if="currentTab === 'activeOperations'")
      DealsList(:showFilters="showFilters" :is_active="0", v-else-if="currentTab === 'finishedOperations'")
</template>

<script>
import { mapGetters } from 'vuex';
import DealsList from './DealsList';
import analytics from '../../components/mixins/analytics';
import { CHANGE_OPERATIONS_TAB } from '../../store/actions/user';
import cfg from '../../../config';
import { DASHBOARD_DEALS } from '../../store/actions/dashboard';

export default {
  name: 'DealsContainer',
  components: {
    DealsList
  },
  mixins: [analytics],
  props: {
    showFilters: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      currentTab: 'activeOperations',
    };
  },
  computed: {
    ...mapGetters([
      'getActiveDealsCount',
      'loading',
    ]),
    activeDeals() {
      return this.getActiveDealsCount > 0 ? `(${this.getActiveDealsCount})` : '';
    },
    isLoading() {
      return this.loading(DASHBOARD_DEALS) === 'loading';
    },
  },
  methods: {
    changeTab(tab) {
      if (!this.isLoading) {
        this.currentTab = tab;
        this.$store.commit(CHANGE_OPERATIONS_TAB, tab);
        if (tab === 'activeOperations') {
          this.sendAnalytics('Operations', 'operations_active_open', false);
        } else {
          this.sendAnalytics('Operations', 'operations_finished_open', false);
        }
      }
    },
    open() {
      this.$prompt('Введите ID сделки, которую хотите просмотреть', 'Поиск сделки по ID ', {
        confirmButtonText: 'Перейти к сделке',
        cancelButtonText: 'Отмена',
      }).then(dealID => {
        this.$router.push({
          name: 'Deal',
          params: {id: dealID.value},
        });
      });
    },
  }
};
</script>

<style scoped>
  .tabs-content .tabs > section {
    display: block;
  }

  .tabs a img {
    width: 50px;
    padding-left: 20px;
    padding-bottom: 8px;
  }

  .tabs a img:hover {
    cursor: pointer;
  }
</style>

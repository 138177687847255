import { render, staticRenderFns } from "./FCheckbox.vue?vue&type=template&id=c60bc438&scoped=true&lang=pug&"
import script from "./FCheckbox.vue?vue&type=script&lang=js&"
export * from "./FCheckbox.vue?vue&type=script&lang=js&"
import style0 from "./FCheckbox.vue?vue&type=style&index=0&id=c60bc438&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c60bc438",
  null
  
)

export default component.exports